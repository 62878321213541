import HomePage from "./02-course-school";
import BackToTop from "./backToTop";
import ApiService from "@/utils/ApiServices";
import ApiUrl from "@/utils/ApiUrl";

export default function Home({ allPrograms }) {
  return (
    <>
      <main className="">
        <HomePage allPrograms={allPrograms} />
        <BackToTop />
      </main>
    </>
  );
}
export const getServerSideProps = async () => {
  try {
    const response = await ApiService.getApiService(ApiUrl.GET_ALL_PROGRAMS);
    const allPrograms = response?.results || [];
    return { props: { allPrograms } };
  } catch (error) {
    console.error("Error fetching all programs in SSR:", error.message);
    return { props: { allPrograms: [] } }; // Return empty array if error occurs
  }
};